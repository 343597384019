import React from 'react';
// COMPONENTS
import Header from '../header';
import { Exchange } from '../../components'

class Container extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Header />
                <main>
                    <Exchange />
                </main>
            </React.Fragment>
        )
    }
}


export default Container;