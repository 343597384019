import React from "react";
import { Menu } from "semantic-ui-react";

import "./Header.scss";

function Header() {
  return (
    <header>
      <Menu>
        <Menu.Item position="left">
          <img src="/img/zus-logo.svg" alt="Züs" style={{ width: 175 }} />
        </Menu.Item>
      </Menu>
    </header>
  );
}

export default Header;
