import React from "react";
import { Table } from "semantic-ui-react";
// SCSS FILE
import "./Exchange.scss";

class Exchange extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="content-area ui container">
          <div className="container-fluid">
            <h1 className="text-uppercase text-center c-h1">GET ZCN</h1>

            <div>
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Exchange</Table.HeaderCell>
                    <Table.HeaderCell>Pair</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <strong>1inch</strong>
                    </Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://app.1inch.io/#/1/unified/swap/ETH/ZCN"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <strong>ZCN-ETH</strong>
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Gate</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.gate.io/trade/zcn_usdt"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ZCN-USDT
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Gate</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://www.gate.io/trade/zcn_eth"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ZCN-ETH
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Uniswap (v2)</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://v2.info.uniswap.org/pair/0xa6890ac41e3a99a427bef68398bf06119fb5e211"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ZCN-ETH
                      </a>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Bancor</Table.Cell>
                    <Table.Cell>
                      <a
                        href="https://app.bancor.network/trade?from=0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE&to=0xb9EF770B6A5e12E45983C5D80545258aA38F3B78"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ZCN-ETH
                      </a>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Exchange;
