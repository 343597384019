import React from 'react';

// MAIN LAYOUT
import * as  MainLayout from './mainLayout'


function App() {
  return (
    <div className="app">
      <MainLayout.Container/>
    </div>
  );
}

export default App;
